<template>
  <div class="home">
    <!--ACCOUNT INFO-->
    <h1>Hi, {{accounts[0].username}}</h1>

    <h3>Total: {{accounts[0].total}} {{accounts[0].money}}</h3>

    <button @click="resetAll()">RESET ALL</button>

    <!--list of envelopes-->

    <h2>Envelopes:</h2>
    <h3>Monthly:</h3>
    <div v-for="(i, index) in envelopes" :key="i">
      <h3 v-if="i.type=='monthly'" style="font-size:25px; border:1px solid black" @click="filter(i.name)">{{i.name}} {{i.maxValue}}{{i.money}} {{i.value}}{{i.money}}<svg @click="deleteEnvelopes(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg> <ion-icon name="create-outline" @click="editEnvelopes(index)"></ion-icon> 
        <div :class="'envelope'+index" style="display:none">
          <input type="text" :placeholder="i.name" v-model="envelopes_edit_newName"> 
          <input type="number" :placeholder="i.maxValue" v-model="envelopes_edit_newValue">
          <select v-model="envelopes_edit_newMoney">
            <option v-for="i in this.currency" :key="i">{{i.name}}</option>
          </select> 
          <button @click="editEnvelopesSave(index,i.name, envelopes_edit_newName, envelopes_edit_newValue, envelopes_edit_newMoney)">save</button>
          <button @click="editEnvelopesCancel(index)">cancel</button>
        </div> 
      </h3>
    </div>

    <h3>Annual:</h3>
    <div v-for="(i, index) in envelopes" :key="i">
      <h3 v-if="i.type=='annual'" style="font-size:25px; border:1px solid black" @click="filter(i.name)">{{i.name}} {{i.maxValue}}{{i.money}} {{i.value}}{{i.money}} <svg @click="deleteEnvelopes(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg> <ion-icon name="create-outline" @click="editEnvelopes(index)"></ion-icon> 
        <div :class="'envelope'+index" style="display:none">
          <input type="text" :placeholder="i.name" v-model="envelopes_edit_newName"> 
          <input type="number" :placeholder="i.maxValue" v-model="envelopes_edit_newValue">
          <select v-model="envelopes_edit_newMoney">
            <option v-for="i in this.currency" :key="i">{{i.name}}</option>
          </select> 
          <button @click="editEnvelopesSave(index,i.name, envelopes_edit_newName, envelopes_edit_newValue, envelopes_edit_newMoney)">save</button>
          <button @click="editEnvelopesCancel(index)">cancel</button>
        </div> </h3>
    </div>

    <h3>Daily</h3>
    <div v-for="(i, index) in envelopes" :key="i">
      <h3 v-if="i.type=='daily'" style="font-size:25px; border:1px solid black" @click="filter(i.name)">{{i.name}} {{i.maxValue}}{{i.money}} {{i.value}}{{i.money}} <svg @click="deleteEnvelopes(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg>  <ion-icon name="create-outline" @click="editEnvelopes(index)"></ion-icon> 
        <div :class="'envelope'+index" style="display:none">
          <input type="text" :placeholder="i.name" v-model="envelopes_edit_newName"> 
          <input type="number" :placeholder="i.maxValue" v-model="envelopes_edit_newValue">
          <select v-model="envelopes_edit_newMoney">
            <option v-for="i in this.currency" :key="i">{{i.name}}</option>
          </select> 
          <button @click="editEnvelopesSave(index,i.name, envelopes_edit_newName, envelopes_edit_newValue, envelopes_edit_newMoney)">save</button>
          <button @click="editEnvelopesCancel(index)">cancel</button>
        </div> 
      
      </h3>
    </div>

    <h3>Weekly</h3>
    <div v-for="(i, index) in envelopes" :key="i">
      <h3 v-if="i.type=='weekly'" style="font-size:25px; border:1px solid black" @click="filter(i.name)">{{i.name}} {{i.maxValue}}{{i.money}} {{i.value}}{{i.money}} <svg @click="deleteEnvelopes(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg>  <ion-icon name="create-outline" @click="editEnvelopes(index)"></ion-icon> 
        <div :class="'envelope'+index" style="display:none">
          <input type="text" :placeholder="i.name" v-model="envelopes_edit_newName"> 
          <input type="number" :placeholder="i.maxValue" v-model="envelopes_edit_newValue">
          <select v-model="envelopes_edit_newMoney">
            <option v-for="i in this.currency" :key="i">{{i.name}}</option>
          </select> 
          <button @click="editEnvelopesSave(index,i.name, envelopes_edit_newName, envelopes_edit_newValue, envelopes_edit_newMoney)">save</button>
          <button @click="editEnvelopesCancel(index)">cancel</button>
        </div> </h3>
    </div>

    <h3>Every_2_weeks</h3>
    <div v-for="(i, index) in envelopes" :key="i">
      <h3 v-if="i.type=='every_2_weeks'" style="font-size:25px; border:1px solid black" @click="filter(i.name)">{{i.name}} {{i.maxValue}}{{i.money}} {{i.value}}{{i.money}} <svg @click="deleteEnvelopes(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg>  <ion-icon name="create-outline" @click="editEnvelopes(index)"></ion-icon> 
        <div :class="'envelope'+index" style="display:none">
          <input type="text" :placeholder="i.name" v-model="envelopes_edit_newName"> 
          <input type="number" :placeholder="i.maxValue" v-model="envelopes_edit_newValue">
          <select v-model="envelopes_edit_newMoney">
            <option v-for="i in this.currency" :key="i">{{i.name}}</option>
          </select> 
          <button @click="editEnvelopesSave(index,i.name, envelopes_edit_newName, envelopes_edit_newValue, envelopes_edit_newMoney)">save</button>
          <button @click="editEnvelopesCancel(index)">cancel</button>
        </div> </h3>
    </div>


    
    <h3>Debt:</h3>
    <div v-for="(i, index) in this.debt" :key="i">
      <h3  style="font-size:25px; border:1px solid black"  @click="filter(i.name)">{{i.name}}:<span style="color:red">{{i.fullPrice}}{{i.fullPriceMoney}}</span>  pay/mounth:<span style="color:red">{{i.monthlyPay}}{{i.monthlyPayMoney}}</span> pay/mounth Remaining: <span style="color:red">{{i.monthlyPayRemaining}}{{i.monthlyPayMoney}}</span>  Paid so far: <span style="color:red">{{i.paidSoFar}}{{i.fullPriceMoney}}</span> interestRate:{{i.interestRate}}%<svg @click="deleteDebt(index,i.name)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><path d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z" fill="currentColor"/></g></svg> <ion-icon name="create-outline" @click="editDebt(index)"></ion-icon> 
        <div :class="'debt'+index" style="display:none;">
          <input type="text" :placeholder="'New name'" v-model="debt_edit_newName"> 
          <input type="number" :placeholder="'Change Monthly Payment'" v-model="debt_edit_newPayMounth">
          <input type="number" :placeholder="'Change interestRate'+'%'" v-model="debt_edit_newInterestRate">

          <button @click="editDebtSave(index,i.name, debt_edit_newName, debt_edit_newPayMounth,debt_edit_newInterestRate, i.monthlyPay-i.monthlyPayRemaining)">save</button>
          <button @click="editDebtCancel(index)">cancel</button>
        </div> </h3>
      <pie-chart :data="[[`Remaining payment (${this.procent(i.fullPrice-i.paidSoFar,i.fullPrice)}%)`, i.fullPrice-i.paidSoFar], [`Paid so far (${this.procent(i.paidSoFar,i.fullPrice)}%)`, i.paidSoFar]]"></pie-chart>
    </div>

    <br><br>



    <!--ADD-->
    <div class="add">
      <!--ADD ENVELOPES-->
      <div class="add_envelopes">
        <h3>Add more envelopes:</h3>
        <h4>Name:</h4><input type="text" v-model="envelopes_name">
        <h4>Amount:</h4><input type="number" v-model="envelopes_amount">
        <select v-model="envelopes_money">
          <option v-for="i in this.currency" :key="i">{{i.name}}</option>
        </select>
        <h4>Type:</h4>
        <select v-model="envelopes_type">
          <option value="monthly">Monthly</option>
          <option value="annual">Annual</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="every_2_weeks">Every 2 Weeks</option>
        </select>
        <button @click="addEnvelopes()">ADD</button>
      </div>

      <!--ADD DEBT-->
      <div class="add_debt">
        <h3>Add debt:</h3>
        <h4>Name: <input type="text" v-model="debt_name"></h4>

        <h4>Full price <input type="number" v-model="debt_fullPrice"></h4>
        <select v-model="debt_fullPriceMoney">
          <option v-for="i in this.currency" :key="i">{{i.name}}</option>
        </select>

        <h4>~Monthly Payment<input type="number" v-model="debt_month"></h4>
        <select v-model="debt_monthMoney">
          <option v-for="i in this.currency" :key="i">{{i.name}}</option>
        </select>

        <h4>Interest Rate <input type="number" v-model="debt_interestRate" placeholder="%"></h4>

        <button @click="addDebt()">ADD</button>
      </div>


      <!--ADD TRANSACTIONS-->
      <div class="add_transaction" style="border:1px solid purple; width:500px">
        <!--type of transactions-->
        <h3>Add transactions:</h3>
        <h4>Type:</h4>
        <select v-model="type">
          <option value="expense">Expense</option>
          <option value="transfer">Transfer</option>
          <option value="income">Income</option>
          <option value="debt">Debt Transaction</option>
        </select><br><br>
        
        <!--Add expenses-->
        <div class="add_expense"  v-if="this.type==='expense'">
          <form>
            <label>Date <input type="date" v-model="transaction_data"><input type="time" v-model="transaction_time"></label>
            <label>Payee <input type="text" required v-model="transaction_text"></label>
            <label>
              Amount 
              <input type="number" required v-model="transaction_number">
              <select v-model="expense_money">
                <option v-for="i in this.currency" :key="i">{{i.name}}</option>
              </select>
            </label>
            <label>Notes <input type="text" v-model="transaction_notes"></label>
            <select v-model="selected">
              <option disabled>Select Envelope</option>
              <option v-for="i in this.envelopes" :key="i">{{i.name}}</option>
            </select>

            <p>Account:</p>
            <select v-model="transaction_account">
              <option v-for="i in this.accounts" :key="i">{{i.username}}</option>
            </select>
            
          </form>
          <button @click="addTransactionExpense()">Add</button>
        </div>

        <!--make a transfer-->
        <div class="add_transfer" v-else-if="this.type==='transfer'">
          <form>
            <label>Date <input type="date" v-model="transfer_date"> <input type="time" v-model="transfer_time"></label>
            <label>Name <input type="text" required v-model="transfer_name"></label>
            <label>
              Amount 
              <input type="text" required v-model="transfer_amount">
              <select v-model="transfer_money">
                <option v-for="i in this.currency" :key="i">{{i.name}}</option>
              </select>
            </label>
            <h4>Type:</h4>
            <select v-model="transfer_type">
              <option value="envelope">Envelope Transfer</option>
              <option value="account">Account Transfer</option>
            </select>

            <div class="envelope_transfer" v-if="this.transfer_type=='envelope'">
              <p>From:</p>
              <select v-model="transfer_type_envelope_from">
              <option v-for="i in this.envelopes" :key="i">{{i.name}}</option>
              </select>

              <p>To:</p>
              <select v-model="transfer_type_envelope_to">
              <option v-for="i in this.envelopes" :key="i">{{i.name}}</option>
              </select>
            </div>

            <div class="account_transfer" v-else-if="this.transfer_type=='account'">
              <h4>You cannot transfer to/from the same Account. Upgrade to Goodbudger Plus!</h4>
            </div>
            <select v-model="transfer_account">
              <option v-for="i in this.accounts" :key="i">{{i.username}}</option>
            </select>
            <label>Notes <input type="text" v-model="transfer_notes"></label>
            
          </form>
          <button @click="addTransactionTransfer()">Add</button>
        </div>


        <!--ADD income-->
        <div class="add_income" v-else-if="this.type==='income'">
          <form>
            <label>Date <input type="date" v-model="income_date"> <input type="time" v-model="income_time"></label>
            <label>Payee <input type="text" required v-model="income_name"></label>
            <label>
              Amount 
              <input type="number" required v-model="income_amount">
              <select v-model="income_money">
                <option v-for="i in this.currency" :key="i">{{i.name}}</option>
              </select>
            </label>
            <label>Notes <input type="text" v-model="income_notes"></label>
            <p>Account:</p>
            <select v-model="income_account">
              <option v-for="i in this.accounts" :key="i">{{i.username}}</option>
            </select>
          </form>
          <button @click="addTransactionIncome()">Add</button>
        </div>

        <!--ADD DEBT TRANSACTION-->
        <div class="add_debt" v-else-if="this.type==='debt'">
          <p v-if="this.debt.length==0">You can track credit cards and debts that you're working to pay off. Go to Edit Accounts to get started.</p>
          <div v-else>
            <form>
              <label>Date <input type="date" v-model="ADDdebt_date"> <input type="time" v-model="ADDdebt_time"></label>
              <label>Name <input type="text" required v-model="ADDdebt_name"></label>
              <label>
                Amount 
                <input type="number" required v-model="ADDdebt_amount">
                <select v-model="ADDdebt_money">
                  <option v-for="i in this.currency" :key="i">{{i.name}}</option>
                </select>
              </label>
              <label>
                Debt:
                <select v-model="ADDdebt_debt">
                  <option v-for="i in this.debt" :key="i">{{i.name}}</option>
                </select>
              </label>
              <label>Notes <input type="text" v-model="ADDdebt_notes"></label>
              <p>Account:</p>
              <select v-model="ADDdebt_account">
                <option v-for="i in this.accounts" :key="i">{{i.username}}</option>
              </select>
              <label>
                Check #: 
                <input type="number" v-model="ADDdebt_check">
              </label>
            </form>
            <button @click="addTransactionDebt()">Add</button>
          </div>
        </div>

      </div>
      
    </div>

    <!--HISTORY-->
    <h3>History transaction</h3>
    <button @click="clearFilter()">Clear Filter</button>
    <div v-if="this.filterTransaction==false">
      <div class="history" v-for="(i,index) in transaction" :key="i" :class="this.transactionType(i.type)">
        <div style="display:flex; align-items:center;">
          <p>
            <span v-if="i.date!=undefined">{{i.date}}</span> <span v-else>{{this.getTime()}}</span> | 
            <span v-if="i.time!=''">{{i.time}}</span> <span v-else>{{this.getTimeMin()}}</span> |
            {{i.name}} | 
            {{i.envelopes}} <span v-if="i.envelopes_to!=undefined">-> {{i.envelopes_to}}</span> | 
            {{i.value}} - {{i.money}} | 
            {{i.notes}} | 
            {{i.account}}
          </p>
          <ion-icon name="close-circle-outline" @click="deleteTransaction(index, i.envelopes, i.valueInEnvelopeCurrency,i.value,i.money, i.type,i.envelopes_to)"></ion-icon>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="history" v-for="i in transactionFilter" :key="i" :class="this.transactionType(i.type)">
        <p>
          <span v-if="i.date!=undefined">{{i.date}}</span> <span v-else>{{this.getTime()}}</span> | 
          <span v-if="i.time!=''">{{i.time}}</span> <span v-else>{{this.getTimeMin()}}</span> |
          {{i.name}} | 
          {{i.envelopes}} <span v-if="i.envelopes_to!=undefined">-> {{i.envelopes_to}}</span> | 
          {{i.value}} - {{i.money}} | 
          {{i.notes}} | 
          {{i.account}}
        </p>
      </div>
      <p>*chart data is in envelop  ({{this.transactionFilter[0].envelopes}}) currency*</p>
      <pie-chart :data="this.envelopesFilterChart()" :download="true"></pie-chart>
    </div>

    <div v-if="this.transaction.length!=0" style="display:flex; width:100%;justify-content:space-between;">
      <div>
        <p class="center">Expense chart</p>
        <pie-chart :data="this.transactionsChart()[0]" :download="true"></pie-chart>
      </div>
      <div>
        <div class="center">data in account currency ({{this.accounts[this.currentAccount].money}})</div>
        <p class="center">Income chart</p>
        <pie-chart :data="this.transactionsChart()[1]" :download="true"></pie-chart>
      </div>
      <div class="center">
        <p>Transfer chart</p>
        <pie-chart :data="this.transactionsChart()[2]" :download="true"></pie-chart>
      </div>
    </div>

    <p class="center">*chart data is in account currency* ({{this.accounts[this.currentAccount].money}})</p>

    <h3 class="center">Envelopes stats</h3>
    <pie-chart :data="this.envelopesChart()" :download="true"></pie-chart>

    <h3 class="center">Income vs Spending</h3>
    <column-chart :data="[[`Income (${this.procent(incomeTotal, incomeTotal+expenseTotal)}%)`, this.incomeTotal], [`Expense (${this.procent(expenseTotal, incomeTotal+expenseTotal)}%)`, this.expenseTotal]]" :colors="['green', 'red']" style="width:30%; margin-left:auto; margin-right:auto"></column-chart>

    <div class="time_calculator">
      <p>How much time does this cost you:</p>
      <div style="display:flex;">
        <p>What you want to buy</p>
        <input type="text" v-model="time_name">
      </div> 

      <div style="display:flex;">
        <p>How much does it cost</p>
        <input type="number" v-model="time_cost">
        <select v-model="timeCost_money">
          <option v-for="i in this.currency" :key="i">{{i.name}}</option>
        </select>
      </div>

      <div style="display:flex;">
        <p>How much do you earn per hour</p>
        <input type="number" v-model="time_hour">
        <select v-model="timeHour_money">
          <option v-for="i in this.currency" :key="i">{{i.name}}</option>
        </select>
      </div>


      <input type="submit" value="submit" @click="timeCalculator()">
      <p v-if="this.time_result!=''">
        {{this.time_result}}
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios');

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      currentAccount: 0,
      filterTransaction: false,
      accounts: [ //db
        {username: 'Tudor', total: 0, money:'USD'},
      ],
      envelopes: [ //db
        {name:'Gas', maxValue: 100, value: 0, money: 'EUR', type:'monthly',maxValueInAccountMoney: 117.26},
        {name:'Groceries', maxValue: 240, value: 0, money: 'RON', type: 'monthly', maxValueInAccountMoney: 57.49},
        {name:'Savings', maxValue: 1000, value: 0, money: 'USD', type: 'annual', maxValueInAccountMoney: 1000},
      ],
      transaction: [ //db
        //{name:'rent', value:'500', money:'EUR', date:'9/11/2021',envelopes:'gas', notes:'',type:'', account: 'Tudor'},
      ],
      debt:[], //db
      expenseTotal:0, 
      incomeTotal:0, 
      transactionFilter:[],
      currency:[
        {name:'USD',value:1},
        {name:'EUR', value:2},
        {name:'RON', value:3},
      ],
      type:'', //transaction type
      transfer_type:'',
      transfer_type_envelope_from: '',
      transfer_type_envelope_to: '',
      expense_money: '',
      income_money: '',
      income_time:'',
      transfer_money: '',
      transaction_time:'',
      transfer_time:'',
      envelopes_money: '',
      envelopes_type: '',
      moneyExchange:null,
      time_name:'',
      time_hour:'',
      time_cost:'',
      time_result:'',
      timeCost_money:'',
      timeHour_money:'',
      debt_name:'',
      debt_fullPrice:'',
      debt_fullPriceMoney:'',
      debt_month:'',
      debt_monthMoney:'',
      debt_interestRate:'',
      
      ADDdebt_money:'',
      ADDdebt_debt:'',
      ADDdebt_account:'',

      envelopes_edit_newName:'',
      envelopes_edit_newValue:'',
      envelopes_edit_newMoney:'',

      debt_edit_newName:'',
      debt_edit_newPayMounth:'',
      debt_edit_newInterestRate:'',
    }
  },
  methods: {
    resetAll() {
      if(confirm('are you sure?')) {
        this.envelopes = [];
        this.debt = [];
        this.transaction = [];
        this.accounts[this.currentAccount].total = 0;
        this.expenseTotal = 0;
        this.incomeTotal = 0;
      }
    },
    envelopesChart() {
      var arr = [],s=0;
      for(let i=0;i<this.envelopes.length;i++) {
        s+=this.envelopes[i].maxValueInAccountMoney;
      }
      for(let i=0;i<this.envelopes.length;i++) {
        arr.push([`${this.envelopes[i].name}(${this.procent(this.envelopes[i].maxValueInAccountMoney,s)}%)`, this.envelopes[i].maxValueInAccountMoney]);
      }
      return arr;
    },
    envelopesFilterChart() {
      var arr = [],s=0;
      for(let i=0;i<this.transactionFilter.length;i++) {
        s+=this.transactionFilter[i].valueInEnvelopeCurrency;
      }
      for(let i=0;i<this.transactionFilter.length;i++) {
        arr.push([`${this.transactionFilter[i].name}(${this.procent(this.transactionFilter[i].valueInEnvelopeCurrency,s)}%)`, this.transactionFilter[i].valueInEnvelopeCurrency]);
      }
      return arr;
    },
    transactionsChart() {
      var arr = [],expense=[],income=[],transfer=[],sE=0,sI=0,sT=0;
      for(let i=0;i<this.transaction.length;i++) {
        if(this.transaction[i].type=='-') {
          sE+=this.transaction[i].valueInAccountCurrency;
        } else if(this.transaction[i].type=='+') {
          sI+=this.transaction[i].valueInAccountCurrency;
        } else {
          sT+=this.transaction[i].valueInAccountCurrency;
        }
      }

      for(let i=0;i<this.transaction.length;i++) {
        if(this.transaction[i].type=='-') {
          expense.push([`${this.transaction[i].name} (${this.procent(this.transaction[i].valueInAccountCurrency,sE)}%)`,this.transaction[i].valueInAccountCurrency]);
        } else if(this.transaction[i].type=='+') {
          income.push([`${this.transaction[i].name} (${this.procent(this.transaction[i].valueInAccountCurrency,sI)}%)`,this.transaction[i].valueInAccountCurrency]);
        } else {
          transfer.push([`${this.transaction[i].name} (${this.procent(this.transaction[i].valueInAccountCurrency,sT)}%)`,this.transaction[i].valueInAccountCurrency]);
        }
      }
      
      arr.push(expense,income,transfer);
      return arr;
    },
    procent(x,s) {
      //x - nr
      //s - suma
      let procent = ((x*100)/s).toFixed(2);
      return procent;
    },
    getTime() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + '/' + dd + '/' + yyyy;
      return today;
    },
    getTimeMin() {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes();
      return time;
    },
    transactionType(input) {
      if(input == '+') {
        return 'green';
      } else if(input == '-') {
        return 'red';
      } else {
        return 'purple';
      }
    },
    async addEnvelopes() {
      await this.changeMoney(this.envelopes_money,this.accounts[this.currentAccount].money,this.envelopes_amount);

      this.envelopes.push({
        name:this.envelopes_name, 
        maxValue:this.envelopes_amount, 
        value: 0, 
        money: this.envelopes_money,
        type: this.envelopes_type,
        maxValueInAccountMoney: this.moneyExchange
      });
      
      //console.log(this.moneyExchange);
    },

    editEnvelopes(index) {
      document.querySelector(`.envelope${index}`).style.display='block';

      for(let i=0;i<this.envelopes.length;i++) {
        if(i!=index) {
          document.querySelector(`.envelope${i}`).style.display='none';
        }
      }
      //
    },

    async editEnvelopesSave(index,name, newNname, value, money) {



      for(let i=0;i<this.envelopes.length;i++) {
        if(this.envelopes[i].name == name) {
          await this.changeMoney(this.envelopes[i].money, money, this.envelopes[i].value);
          this.envelopes[i].name = newNname;
          this.envelopes[i].maxValue = value;
          this.envelopes[i].money = money;
          this.envelopes[i].value = this.moneyExchange;
        }
      }

      for(let i=0;i<this.transaction.length;i++) {
        if(this.transaction[i].envelopes == name) {
          await this.changeMoney(this.transaction[i].money, money, this.transaction[i].value);
          this.transaction[i].envelopes = newNname;
          this.transaction[i].valueInEnvelopeCurrency = this.moneyExchange;
        }
      }

      this.envelopes_edit_newName='';
      this.envelopes_edit_newValue='';
      this.envelopes_edit_newMoney='';

      document.querySelector(`.envelope${index}`).style.display='none';
    },

    editEnvelopesCancel(index) {
      document.querySelector(`.envelope${index}`).style.display='none';
    },

    deleteEnvelopes(index,name) {
      if(confirm('Are you sure?')) {
        this.accounts[this.currentAccount].total+=this.envelopes[index].value;
        this.envelopes.splice(index,1);


        this.transaction = this.transaction.filter(function( obj ) {
          return obj.envelopes !== name;
        });
      }
    },

    async deleteTransaction(index, name, amount,amount1,currency, type, transferTo) {
      //amount - amount ul tranzactiei in banii envelope ului
      //amount1 - amount ul tranzactiei 
      if(confirm('Are you sure?')) {
        await this.changeMoney(currency, this.accounts[this.currentAccount].money, amount1); //schimba in banii contului
        this.transaction.splice(index, 1);
        if(type == '-') {
          for(let i=0;i<this.envelopes.length;i++) {
            if(this.envelopes[i].name == name) {
              //await this.changeMoney(currency, this.envelopes[i].money, amount);
              this.envelopes[i].value-= amount.toFixed(2)*1;
            }
          }
          this.accounts[this.currentAccount].total+=this.moneyExchange;
        }

        if(type == '+') {
          this.accounts[this.currentAccount].total-=this.moneyExchange.toFixed(2)*1;
        }
        if(type == '<') {
            for(let i=0;i<this.debt.length;i++) {
              if(name == this.debt[i].name) {
                await this.changeMoney(currency, this.debt[i].monthlyPayMoney, amount1);
                this.debt[i].monthlyPayRemaining+=this.moneyExchange;

                await this.changeMoney(currency, this.debt[i].fullPriceMoney, amount1);
                this.debt[i].paidSoFar-=this.moneyExchange;
                //this.debt[i].fullPrice-=this.ADDdebt_amount;
                break;
              }
            }
        }

        if(type == '~') { 
          for(let i=0;i<this.envelopes.length;i++) {
            if(name==this.envelopes[i].name) {
              await this.changeMoney(currency,this.envelopes[i].money,amount1);
              this.envelopes[i].value+=this.moneyExchange;
            }
            if(transferTo ==this.envelopes[i].name) {
              await this.changeMoney(currency,this.envelopes[i].money,amount1);
              this.envelopes[i].value=parseInt(this.envelopes[i].value) - parseInt(this.moneyExchange);
            }
          }
        }
      }
    },

    addDebt() {
      this.debt.push({
        name:this.debt_name,
        fullPrice:this.debt_fullPrice,
        fullPriceMoney: this.debt_fullPriceMoney,
        monthlyPay: (this.debt_interestRate/100*this.debt_month)+this.debt_month,
        monthlyPayRemaining: (this.debt_interestRate/100*this.debt_month)+this.debt_month,
        monthlyPayMoney: this.debt_monthMoney,
        interestRate: this.debt_interestRate,
        paidSoFar:0,
      });
      
      console.log(this.debt);
    },
    deleteDebt(index,name) {
      if(confirm('Are you sure?')) {
        this.debt.splice(index,1);


        this.transaction = this.transaction.filter(function( obj ) {
          return obj.envelopes !== name;
        });
      }
    },

    editDebt(index) {
      document.querySelector(`.debt${index}`).style.display='block';

      for(let i=0;i<this.debt.length;i++) {
        if(i!=index) {
          document.querySelector(`.debt${i}`).style.display='none';
        }
      }
    },

    editDebtSave(index, name, newName, newPayMounth, newInterestRate, paid_mounth) {
      for(let i=0;i<this.debt.length;i++) {
        if(this.debt[i].name == name) {
          this.debt[i].name = newName;
          this.debt[i].monthlyPay = ((newInterestRate/100)*newPayMounth)+newPayMounth;
          this.debt[i].monthlyPayRemaining =((newInterestRate/100)*newPayMounth)+newPayMounth - paid_mounth;
          this.debt[i].interestRate = newInterestRate;
        }
      }

      for(let i=0;i<this.transaction.length;i++) {
        if(this.transaction[i].envelopes == name) {
          this.transaction[i].envelopes = newName;
        }
      }

      document.querySelector(`.debt${index}`).style.display='none';
    },

    editDebtCancel(index) {
      document.querySelector(`.debt${index}`).style.display='none';
    },

    async addTransactionDebt() {
      this.transaction.push({
        name:this.ADDdebt_name, 
        value:this.ADDdebt_amount, 
        money:this.ADDdebt_money, 
        date:this.ADDdebt_date,
        time: this.ADDdebt_time, 
        envelopes:this.ADDdebt_debt, 
        notes:this.ADDdebt_notes,
        type:'<',
        account: this.ADDdebt_account,
        //valueInEnvelopeCurrency:this.moneyExchange 
      });
      for(let i=0;i<this.debt.length;i++) {
        if(this.ADDdebt_debt == this.debt[i].name) {
          await this.changeMoney(this.ADDdebt_money, this.debt[i].monthlyPayMoney, this.ADDdebt_amount);
          this.debt[i].monthlyPayRemaining-=this.moneyExchange;

          await this.changeMoney(this.ADDdebt_money, this.debt[i].fullPriceMoney, this.ADDdebt_amount);
          this.debt[i].paidSoFar+=this.moneyExchange;
          //this.debt[i].fullPrice-=this.ADDdebt_amount;
          break;
        }
      }
    },

    async addTransactionExpense() {
      let selectedEnvelop;
      for(let i=0;i<this.envelopes.length;i++) {
        if(this.selected==this.envelopes[i].name) {
          selectedEnvelop=this.envelopes[i].money;
          break;
        }
      }
      await this.changeMoney(this.expense_money, selectedEnvelop, this.transaction_number);
      this.transaction.push({
        name:this.transaction_text, 
        value:this.transaction_number, 
        money:this.expense_money, 
        date:this.transaction_data,
        time: this.transaction_time, 
        envelopes:this.selected, 
        notes:this.transaction_notes,
        type:'-',
        account: this.transaction_account,
        valueInEnvelopeCurrency:this.moneyExchange 
      });
      //add value to envelopes
      await this.changeMoney(this.expense_money, this.accounts[this.currentAccount].money, this.transaction_number); //switch to account currency
      this.transaction[this.transaction.length-1].valueInAccountCurrency = this.moneyExchange;

      //this.changeMoney(this.expense_money,'RON',this.transaction_number);
      //console.log(this.moneyExchange);

      for(let i=0;i<this.envelopes.length;i++) {
        if(this.selected==this.envelopes[i].name) {
          await this.changeMoney(this.expense_money,this.envelopes[i].money,this.transaction_number); //schimba monedele bine, dar trebuie dat un click in plus ca sa puna valoarea buna
          this.envelopes[i].value+=this.moneyExchange;
        }
      }
      for(let i=0;i<this.accounts.length;i++) {
        if(this.accounts[i].username == this.transaction_account) {
          await this.changeMoney(this.expense_money,this.accounts[i].money,this.transaction_number);
          this.accounts[i].total-=this.moneyExchange.toFixed(2)*1;
          this.expenseTotal += this.moneyExchange.toFixed(2)*1;
        }
      }
    },

    async addTransactionTransfer() {
      let selectedEnvelop;
      for(let i=0;i<this.envelopes.length;i++) {
        if(this.transfer_type_envelope_to==this.envelopes[i].name) {
          selectedEnvelop=this.envelopes[i].money;
          break;
        }
      }
      await this.changeMoney(this.transfer_money, selectedEnvelop, this.transaction_number);
      this.transaction.push({
        name:this.transfer_name, 
        value:this.transfer_amount,
        money:this.transfer_money, 
        date:this.transfer_date, 
        time: this.transfer_time, 
        envelopes:this.transfer_type_envelope_from, 
        envelopes_to:this.transfer_type_envelope_to, 
        notes:this.transfer_notes,
        type:'~', 
        account: this.transfer_account,
        valueInEnvelopeCurrency: this.moneyExchange
      });
      await this.changeMoney(this.transfer_money, this.accounts[this.currentAccount].money, this.transfer_amount);
      this.transaction[this.transaction.length-1].valueInAccountCurrency = this.moneyExchange;


      for(let i=0;i<this.envelopes.length;i++) {
        if(this.transfer_type_envelope_from==this.envelopes[i].name) {
          await this.changeMoney(this.transfer_money,this.envelopes[i].money,this.transfer_amount);
          this.envelopes[i].value-=this.moneyExchange;
        }
        if(this.transfer_type_envelope_to==this.envelopes[i].name) {
          await this.changeMoney(this.transfer_money,this.envelopes[i].money,this.transfer_amount);
          this.envelopes[i].value=parseInt(this.envelopes[i].value) + parseInt(this.moneyExchange);
        }
      }
    },

    async addTransactionIncome() {
      //const time = this.getTime();
      this.transaction.push({
        name:this.income_name, 
        value:this.income_amount, 
        money:this.income_money, 
        date:this.income_date, 
        time:this.income_time, 
        envelopes:'Unallocated', 
        notes:this.income_notes,
        type:'+', 
        account: this.income_account
      });
      await this.changeMoney(this.income_money, this.accounts[this.currentAccount].money, this.income_amount);
      this.transaction[this.transaction.length-1].valueInAccountCurrency = this.moneyExchange;
      
      for(let i=0;i<this.accounts.length;i++) {
        if(this.accounts[i].username == this.income_account) {
          await this.changeMoney(this.income_money,this.accounts[i].money,this.income_amount);
          this.accounts[i].total+=this.moneyExchange;
          this.incomeTotal += this.moneyExchange;
        }
      }
    },
    filter(input) {
      this.transactionFilter=[];
      for(let i=0;i<this.transaction.length;i++) {
        if(!('envelopes_to' in this.transaction[i])) {
          if(input == this.transaction[i].envelopes) {
              //console.log(this.transaction[i].name);
              this.filterTransaction = true;
              this.transactionFilter.push(this.transaction[i]);
            }
        } 
 
      }
    },
    clearFilter() {
      this.filterTransaction = false;
    },
    async changeMoney(from, to, amount) {
      /*
      await axios.get(`https://romanian-exchange-rate-bnr-api.herokuapp.com/api/convert?access_key=f7dbe1842278-43779b&from=${from}&to=${to}&amount=${amount}`)
      .then((response) =>{
        this.moneyExchange = response.data.result_short;
      })*/
      //console.log(this.moneyExchange);
      await axios.get(`https://v6.exchangerate-api.com/v6/fb8b677f456ab96b524bdd8e/latest/${from}`)
      .then((response) =>{
        let currency = response.data.conversion_rates[to];
        //this.moneyExchange =Math.round(currency*amount * 10) / 10;
        this.moneyExchange = (currency*amount).toFixed(2)*1;
      })
    },

    hoursToTime(input) {
      return `${(input/24).toFixed(0)} days ${(input%24).toFixed(2)} hours`;
    },

    async timeCalculator() {
      await this.changeMoney(this.timeHour_money, this.timeCost_money, this.time_hour);


      if(this.time_cost/this.time_hour>24) {
        this.time_result=`In ${this.hoursToTime((this.time_cost/this.moneyExchange).toFixed(2))} (~${(this.time_cost/this.moneyExchange).toFixed(0)} hours) of your life you will can buy '${this.time_name}'`;
      } else {
        this.time_result=`In ${(this.time_cost/this.moneyExchange).toFixed(2)} of your life hours you will can buy '${this.time_name}'`;
      }
    }
  },


  
  mounted () {
    //const promise = axios.get(`https://v6.exchangerate-api.com/v6/fb8b677f456ab96b524bdd8e/latest/USD`)

    //promise.then((response) =>{
   //   console.log(response.data);
   // })
  },

 
};



</script>
<style scopped lang="scss">
.time_calculator {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  align-items: center;

}
.envelopes0 {
  color: red;
}
.center {
  text-align: center;
}
.add {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.history p{
  font-size: 16px;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.red {
  color: red;
}
.green {
  color: green;
}
.purple {
  color: purple;
}
</style>
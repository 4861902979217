<template>
<div>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">Aboutt</router-link>
  </div>
  
  <router-view />
</div>

</template>
<script>

</script>
<style lang="scss">


</style>
